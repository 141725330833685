.GridCell {
  position: relative;
  width: 32px;
  height: 32px;
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
}

.GridCellContent {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.GridCellSelectionOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}